.LandingPage {
    display: inline-block;
}

.LandingImage {
    margin-right: 25px;
    display: inline-block;
    width: 300px;
    margin-top: 50px;
    margin-bottom: 50px;
}

.Book {
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.5);
}

img {
    width: 100%;
    height: 100%;
}

.Inline {
    margin-top: 50px;
    width: calc(100% - 325px);
    display: inline-block;
    vertical-align: top;
}

.LandingHeader {
    margin: 25px;
    margin-bottom: 0;
    font-size: 30px;
    font-weight: bold;
    font-style: italic;
    text-align: left;
}

.UnderImage {
    max-height: 30px;
    max-width: 100%;
    height: auto;
    width:auto;
}

.LandingBody {
    padding: 25px;
    padding-bottom: 50px;
    font-size: var(--font-size);
    text-align: left;
}

.LandingBody a {
    color: black;
    font-weight: 500;
}

@media (max-width:1000px) {
    .LandingImage {
        width: min(300px, 100% - 50px);
        display: block;
        margin-top: 50px;
        margin-left: auto;
        margin-right: auto;
    }

    img {
        width: 100%;
        height: 100%;
    }

    .Inline {
        width: 100vw;
        margin-top: 0px;
    }
}

.LandingQuote {
    text-align: center;
    font-size: var(--font-size);
    margin-right: 50px;
    margin-left: 50px;
}