
.navbar {
    visibility: visible;

    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    color: #000;
    height: 70px;

    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

    vertical-align: middle;
}

.navbar-brand a {
    color: black;
    text-decoration: none;
}

.navbar-brand {
    font-weight: bolder;
    font-size: var(--font-size);

    padding-top: 10px;
    margin-left: 25px;
}

.navbar-items {
    text-align: right;
    font-size: var(--secondary-font-size);

    padding-top: 13px;
    margin-right: 25px;
    
}

.navbar-item {
    display: inline-block;
    margin-left: 10px;

    color: black;
    text-decoration: none;

    font-weight: light;
}

.navbar-item[clicked=true] {
    font-weight: bolder;
}

.navbar-item:hover {
    font-weight: bolder;
}

.hamburger-navbar {
    display: none;
    visibility: hidden;
}

@media (max-width:800px ) {
    .navbar {
        display: none;
        visibility: hidden;
    }

    .hamburger-navbar {
        visibility: visible;

        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #fff;
        color: #000;
        height: 70px;
    
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    
        vertical-align: middle;
    }
      
    .hamburger-navbar-brand {
        font-weight: bolder;
        font-size: var(--font-size);
    
        padding-top: 10px;
        margin-left: 25px;
    }
    
    .hamburger-navbar-brand a {
        color: black;
        text-decoration: none;
    }
      
    .hamburger-menu-icon {
        font-weight: bolder;
        font-size: var(--font-header-size);
        cursor: pointer;
    
        padding-top: 10px;
        margin-right: 25px;
    }
      
    .hamburger-menu-items {
        z-index: 10;
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 70px;
        left: 0;
        right: 0;
        background-color: #fff;
        color: #000;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    
        padding-bottom: 10px;
    }
      
    .hamburger-menu-items a {
        text-align: left;
        font-size: var(--font-size);
        text-decoration: none;
        color: #000;
        margin-left: 50px;
        padding-bottom: 10px;
    }
    
    .hamburger-menu-items a:hover {
        font-weight: bolder;
    }
    
    .hamburger-menu-items a[current=true] {
        font-weight: bolder;
    }
}