:root {
  --font-size: 20px;
  --font-header-size: 30px;
}

body:before {
  font-family: 'Avenir', sans-serif;

  content: "";
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -10;
  /* background: linear-gradient(rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7)), url('./sea.jpg') no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover; */
  background-image: linear-gradient(rgba(255, 255, 255, 0.85), rgba(255, 255, 255, 0.85)), url("./Images/cover.png");
  background-repeat: repeat;
  background-size: 300px;
}

body {
  font-family: 'Avenir', sans-serif;

  /* margin: 0;
  width: 100vw;
  height: 100vh;

  background-image: linear-gradient(rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7)), url('./sea.jpg');
  background-attachment: fixed;
  background-position: center;
	background-repeat: no-repeat;
	background-size: cover; */
  
}

body::after {
  background-color: black;
}