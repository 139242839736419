.ProfileImage {
    margin: 25px;
    margin-top: 50px;
    margin-bottom: 0;
    width: 250px;
}

.ProfileImage img {
    height: 100%;

    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);

}

.ProfileCopy {
    padding-bottom: 50px;
}

.ProfileName {
    margin: 25px;
    margin-bottom: 0;
    font-size: var(--font-header-size);
    font-weight: bold;
    font-style: italic;
    text-align: left;
}

.ProfileBio {
    margin: 25px;
    font-size: var(--font-size);
    text-align: left;
}

.ProfileBio a {
    color: black;
    font-weight: 500;
}