.Relatives {
    margin: 25px;
}

.Person {
    padding: 25px;
    background-color: white;
    border-radius: 15px;

    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
}